/* amplify styling */
:root
{
    --amplify-primary-color: #f95312;
    --amplify-primary-tint: #ff8555;
    --amplify-primary-shade: #c772ae;

    --amplify-font-family: 'Open Sans';
}

.section
{
    background-color: green;
    width: inherit;
}

/* make sure the initial containers dont go under the navbar */
#root > div > div.container:first-of-type,
#root > div > div.container-fluid:first-of-type
{
    margin-top: 100px;
}

/* landing page */
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;200;300;400&display=swap');

.smartcoupons-navbar
{
    background-color: #00178c;
    font-size: 1.0rem;
}

.landing-page h1
{
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 4rem;
    margin-bottom: 4rem;
    font-weight: 400;
}

.landing-page h2
{
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 2.5rem;
    font-weight: 300;
    margin-bottom: 2rem;
}

.landing-page h3
{
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 200;
    font-size: 1.75rem;
    line-height: 1.25;
}

.landing-page p
{
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 200;
    font-size: 1.5rem;
    line-height: 1.25;
}

.campaign_image_cropped
{
    width: 350px;
}

.landing-page .wrapper.row
{
    padding-top: 50px;
    padding-bottom: 50px;
}

.landing-page .wrapper.row:first-child
{
    padding-top: 0px;
}

.landing-page .instructions-row img
{
    height: 500px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.landing-page .instructions-row p
{
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 200;
    font-size: 1.25rem;
    line-height: 1.25;
    text-align: justify;
}




/* css for dealing with the flipping of cards */
.card-container
{
    perspective: 700px;
    display: grid;
}

.card-flip, .card-container
{
    transform-style: preserve-3d;
    transition: all 0.7s ease;
}

.card-flip div
{
    backface-visibility: hidden;
    transform-style: preserve-3d;
}

.back
{
    transform: rotateY(-180deg);
}

.card-container.flipped .card-flip
{
    transform: rotateY(180deg);
}

.card-flip
{
    display: grid;
    grid-template: 1fr / 1fr;
    grid-template-areas: "frontAndBack";
    transform-style: preserve-3d;
    transition: all 0.7s ease;
}

.front
{
    grid-area: frontAndBack;
}

.back
{
    grid-area: frontAndBack;
    transform: rotateY(-180deg);
}


.link-button
{
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline;
    margin: 0;
    padding: 0;
    color: #008cba;
    text-decoration: none;
}


.link-button:hover,
.link-button:focus
{
    text-decoration: underline;
}


.coupon-image-container
{
    position: relative;
    text-align: center;
}


.coupon-image-container .centered
{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    background-color: #6a8aa3;
    transform: translate(-50%, -50%);
}


.social-button.facebook-button
{
    background-color: #4267b2;
    color: white;
    font-weight: 500;
}


.social-button.google-button
{
    background: #4285F4;
    color: #FFF;
    font-family: Roboto, "Helvetica Neue", Arial, sans-serif;
    font-weight: 500;
}


.social-button.google-button .social-logo
{
    background: #FFF;
    border-radius: 2px;
    padding: 6px;
    height: 34px;
}

.btn-lg.social-button.google-button
{
    padding: 5px;
}

.social-button .social-logo
{
    height: 26px;
    margin-right: 12px;
    text-align: center;
    vertical-align: middle;
}


.sticky
{
    position: sticky;
    top: 65px;
    z-index: 1000;
}


@media print
{
    div.alert
    {
        display: none;
    }

    footer
    {
        display: none;
    }

    .noprint
    {
        display: none;
    }
}

/* these classes could undoubtedly be tidied up A LOT into something neater */
.smartcoupons-modal
{
    background-color: #00178c;
}

.smartcoupons-card .card-header
{
    background-color: #f95312;
}

.smartcoupons-card .card-header .badge
{
    background-color: #00178c;
    font-size: 0.9375rem;
    font-weight: 500;
}

.smartcoupons-card-flipped
{
    background-color: #00178c;
}


.smartcoupons-signin.card-header
{
    background-color: #f95312;
}

.smartcoupons-signin.btn
{
    background-color: #f95312;
    border-color: #f95312;
}

.smartcoupons-signin.dropdown > button
{
    background-color: #f95312;
    border-color: #f95312;
}

.smartcoupons-signup.card-header
{
    background-color: #00178c;
}

.smartcoupons-signup.btn
{
    background-color: #00178c;
    border-color: #00178c;
}

.smartcoupons-forgottenpassword
{
    background-color: #f95312;
}

.smartcoupons-forgottenpassword.btn
{
    background-color: #f95312;
    border-color: #f95312;
}

.footer img
{
    height: 24px;
    margin-top: -5px; /* help align the logos a bit more with the text */
}

/* a rule for the age gate for when it collapses to give the buttons a bit of spacing around them */
@media (max-width: 991.98px)
{
    .agegate .confirmbutton
    {     
        margin-top: 10px;
        margin-bottom: 5px;
    }
}

.walletBarcode
{
    margin-bottom: 15px;
}

.walletBarcode .card-body
{
    background-color: #f95312;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding-left: 0;
    padding-right: 0;
}

@media print
{
    .walletBarcode .card-body
    {
        padding-top: 50px;
        padding-bottom: 50px;
    }
}

.walletBarcode .card-footer
{
    background-color: #00178c;
    color: white;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.walletBarcode svg
{
    border-radius: 10px;
}

.walletBarcode .barcodePrintButton
{
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    cursor: pointer;
    user-select: none;
}

.walletBarcode .barcodePrintButton:active
{
    color: #00178c;
}

/* to better work on mobile, have a floating close button when the page collapses less than 992px */
.modalCloseButton
{
    position: fixed;
    z-index: 10000;
    width: 50px;
    height: 50px;
    padding: 7px 10px;
    border-radius: 25px;
    font-size: 10px;
    text-align: center;
    top: 15px;
}

/* by default hide the footer until we hit 1200px width */
.modal-footer
{
    display: none;
}

@media (min-width: 0px)
{
    .modalCloseButton
    {
        right: 15px;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px)
{
    .modalCloseButton
    {
        display: none;
    }

    .modal-footer
    {
        display: flex;
    }
}

.barcode {
    font-family: 'Libre Barcode 128 Text', cursive;
    font-size: 50px;
    transform: scale(1, 1.5);
    -webkit-transform: scale(1, 1.5); /* Safari and Chrome */
    -moz-transform: scale(1, 1.5); /* Firefox */
    -ms-transform: scale(1, 1.5); /* IE 9+ */
    -o-transform: scale(1, 1.5); /* Opera */
    text-align: center;
    white-space: pre;
}